/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import ogimage from '../../images/events/2023/angacom/ANGA-COM-og-image.jpg';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2023/angacom/ANGA-COM-desktop-hero.jpg';
import mobileBanner from '../../images/events/2023/angacom/ANGA-COM-mobile-hero.jpg';

export default function AngaCom() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (

        <Layout title='ANGA COM 2023 - Witbe' isEvents>
            <SEO
                title='Experience Witbe’s QA Testing Tech at ANGA COM 2023!'
                ogDescription='Explore the latest in QA Automated Testing technology at Witbe’s Booth C35, ANGA COM 2023. Discover faster test scenario programming with new AI algorithms.'
                description='Explore the latest in QA Automated Testing technology at Witbe’s Booth C35, ANGA COM 2023. Discover faster test scenario programming with new AI algorithms.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={1} banner={mobileBanner} /> : <DesktopEventPage id={1} banner={banner} />}
        </Layout>
    );
}
